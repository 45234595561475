import { navigate } from 'gatsby-link'
import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'

const Share = ({ pageContext }) => {
  useEffect(() => navigate(`/q/love-languages/`), [])
  return (
    <Helmet>
      <title>愛之語測驗</title>
      <meta property="og:image" content={`${pageContext.siteUrl}/q/love-languages/${pageContext.id}/${pageContext.type}.png`} />
      <meta name="description" content="六分鐘，得到你和他『最適合的相處建議』" />
    </Helmet>
  )
}

export default Share
